
const productsData = [
  {
    id: 1,
    name: "Full sleeve T-shirt",
    extrades: "Blue color, T-shirt",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    oldprice: 0,
    newprice: 240,
    islable: true,
    lable: 'Trending',
    shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    category: "T-shirts",
    reviews: 132,
    shortservices:[
      { icon: "mdi-sync", value: "10 Days Replacement" },
      { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    ],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Jack & Jones" },
      { type: "Color", value: "Blue" },
      { type: "Material", value: "Cotton" },
      { type: "Weight", value: "140 Gm" },
    ],
  },
  {
    id: 2,
    name: "Half sleeve T-shirt",
    extrades: "Half sleeve, T-shirt",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    oldprice: 240,
    newprice: 225,
    isOffer:true,
    offer:25,
    shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    category: "T-shirts",
    reviews: 132,
    shortservices:[
      { icon: "mdi-sync", value: "10 Days Replacement" },
      { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    ],
 
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Jack & Jones" },
      { type: "Color", value: "Blue" },
      { type: "Material", value: "Cotton" },
      { type: "Weight", value: "140 Gm" },
    ],
  },
  {
    id: 3,
    name: "Hoodie (Green)",
    extrades: "Green color, Hoodie",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    oldprice: 290,
    newprice: 275,
    islike:true,
    shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    category: "T-shirts",
    reviews: 132,
    shortservices:[
      { icon: "mdi-sync", value: "10 Days Replacement" },
      { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    ],

    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Jack & Jones" },
      { type: "Color", value: "Blue" },
      { type: "Material", value: "Cotton" },
      { type: "Weight", value: "140 Gm" },
    ],
  },
  {
    id: 4,
    name: "Full sleeve T-shirt",
    extrades: "Blue color, T-shirt",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    oldprice: 290,
    newprice: 275,
    shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    category: "T-shirts",
    reviews: 132,
    shortservices:[
      { icon: "mdi-sync", value: "10 Days Replacement" },
      { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    ],

    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Jack & Jones" },
      { type: "Color", value: "Blue" },
      { type: "Material", value: "Cotton" },
      { type: "Weight", value: "140 Gm" },
    ],
  },
  {
    id: 5,
    name: "Hoodie (Green)",
    extrades: "Gray color, Hoodie",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    oldprice: 290,
    newprice: 275,
    islike:true,
    shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    category: "T-shirts",
    reviews: 132,
    shortservices:[
      { icon: "mdi-sync", value: "10 Days Replacement" },
      { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    ],
    colorOptions: [
    //   { image: product2, color: "Blue" },
    //   { image: product3, color: "Cyan" },
    //   { image: product4, color: "Green" },
    // ],
     ], specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Jack & Jones" },
      { type: "Color", value: "Blue" },
      { type: "Material", value: "Cotton" },
      { type: "Weight", value: "140 Gm" },
    ],
  },
  {
    id: 7,
    name: "Full sleeve T-shirt",
    extrades: "Blue color, T-shirt",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    oldprice: 242,
    newprice: 0,
    shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    category: "T-shirts",
    reviews: 132,
    shortservices:[
      { icon: "mdi-sync", value: "10 Days Replacement" },
      { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    ],
    colorOptions: [
    //   { image: product2, color: "Blue" },
    //   { image: product3, color: "Cyan" },
    //   { image: product4, color: "Green" },
    ],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Jack & Jones" },
      { type: "Color", value: "Blue" },
      { type: "Material", value: "Cotton" },
      { type: "Weight", value: "140 Gm" },
    ],
  },
  {
    id: 1,
    name: "Half sleeve T-shirt",
    extrades: "Black color, T-shirt",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    oldprice: 240,
    newprice: 225,
    shortspecifications: ['Full Sleeve', 'Cotton', 'All Sizes available', '4 Different Color'],
    category: "T-shirts",
    reviews: 132,
    shortservices:[
      { icon: "mdi-sync", value: "10 Days Replacement" },
      { icon: "mdi-currency-usd-circle", value: "Cash on Delivery available" },
    ],
    colorOptions: [
    //   { image: product2, color: "Blue" },
    //   { image: product3, color: "Cyan" },
    //   { image: product4, color: "Green" },
    ],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Jack & Jones" },
      { type: "Color", value: "Blue" },
      { type: "Material", value: "Cotton" },
      { type: "Weight", value: "140 Gm" },
    ],
  }
]

const recentProducts = [
  {
    id: 1,
    img: "img7",
    name: "Wirless Headphone",
    link: "",
    rating: 4,
    oldPrice: 240,
    newPrice: 225,
  },
  {
    id: 2,
    img: "img4",
    name: "Phone patterned cases",
    link: "",
    rating: 3,
    oldPrice: 150,
    newPrice: 145,
  },
  {
    id: 3,
    img: "img6",
    name: "Phone Dark Patterned cases",
    link: "",
    rating: 4,
    oldPrice: 138,
    newPrice: 135,
  },
]

const comments = [
  {
    id: 1,
    name: "James",
    description:
      "To an English person, it will seem like simplified English, as a skeptical Cambridge",
    date: "11 Feb, 2020",
  },
  {
    id: 2,
    name: "David",
    description:
      "Everyone realizes why a new common language would be desirable",
    date: "22 Jan, 2020",
  },
  {
    id: 3,
    name: "Scott",
    description:
      "If several languages coalesce, the grammar of the resulting",
    date: "04 Jan, 2020",
  },
]

const discountData = [
  { label: "Less than 10%", value: 0 },
  { label: "10% or more", value: 10 },
  { label: "20% or more", value: 20 },
  { label: "30% or more", value: 30 },
  { label: "40% or more", value: 40 },
  { label: "50% or more", value: 50 },
]

const orders = [
  {
    id: "1",
    orderid: "#NZ1572",
    orderdate: "04 Apr, 2020",
    billingname: "Lasse C. Overgaard",
    total: "$172",
    badge: "success",
    paymentStatus: "Paid",
  },
  {
    id: "2",
    orderid: "#NZ1571",
    orderdate: "03 Apr, 2020",
    billingname: "Johan E. Knudsen",
    total: "$165",
    badge: "warning",
    paymentStatus: "unpaid",
  },
  {
    id: "3",
    orderid: "#NZ1570",
    orderdate: "03 Apr, 2020",
    billingname: "Lasse C. Overgaard",
    total: "$146",
    badge: "success",
    paymentStatus: "Paid",
  },
  {
    id: "4",
    orderid: "#NZ1569",
    orderdate: "02 Apr, 2020",
    billingname: "Nikolaj S. Henriksen",
    total: "$183",
    badge: "success",
    paymentStatus: "Paid",
  },
  {
    id: "5",
    orderid: "#NZ1568",
    orderdate: "01 Apr, 2020",
    billingname: "Nikolaj S. Henriksen",
    total: "$160",
    badge: "danger",
    paymentStatus: "Chargeback",
  },
  {
    id: "6",
    orderid: "#NZ1567",
    orderdate: "31 Mar, 2020",
    billingname: "Herbert C. Patton",
    total: "$105",
    badge: "warning",
    paymentStatus: "unpaid",
  },
  {
    id: "7",
    orderid: "#NZ1566",
    orderdate: "30 Mar, 2020",
    billingname: "Nikolaj S. Henriksen",
    total: "$112",
    badge: "success",
    paymentStatus: "Paid",
  },
  {
    id: "8",
    orderid: "#NZ1565",
    orderdate: "29 Mar, 2020",
    billingname: "Mathias N. Klausen",
    total: "$123",
    badge: "success",
    paymentStatus: "Paid",
  },
  {
    id: "9",
    orderid: "#NZ1563",
    orderdate: "28 Mar, 2020",
    billingname: "Herbert C. Patton",
    total: "$141",
    badge: "success",
    paymentStatus: "Paid",
  },
  {
    id: "10",
    orderid: "#NZ1562",
    orderdate: "28 Mar, 2020",
    billingname: "Lasse C. Overgaard",
    total: "$164",
    badge: "warning",
    paymentStatus: "unpaid",
  },
  {
    id: "11",
    orderid: "#NZ1561",
    orderdate: "27 Mar, 2020",
    billingname: "James Hamilton",
    total: "$154",
    badge: "success",
    paymentStatus: "Paid",
  },
]

const cartData = {
  products: [
    {
      id: 1,
      name: "Full sleeve T-shirt",
      color: "Blue",
      price: "240",
      data_attr: 2,
      total: 480,
    },
    {
      id: 2,
      name: "Half sleeve T-shirt",
      color: "Red",
      price: "225",
      data_attr: 1,
      total: 225,
    },
    {
      id: 3,
      name: "Hoodie (Green)",
      color: "Green",
      price: "275",
      data_attr: 2,
      total: 550,
    },
    {
      id: 4,
      name: "Hoodie (Gray)",
      color: "Gray",
      price: "275",
      data_attr: 1,
      total: 275,
    },
  ],
  orderSummary: {
    grandTotal: "$ 1,857",
    discount: "$ 157",
    shippingCharge: "$ 25",
    estimatedTax: "$ 19.22",
    total: "$ 1744.22",
  },
}

const customerData = [
  {
    id: "1",
    customerid: "#NZ1562",
    username: "Stephen Rash",
    phone: "325-250-1106",
    email: "StephenRash@teleworm.us",
    address: "2470 Grove Street Bethpage, NY 11714",
    rating: "4.2",
    walletBalance: "$5412",
    joiningDate: "07 Oct, 2019",
  },
  {
    id: "2",
    customerid: "#NZ1561",
    username: "Juan Mays",
    phone: "443-523-4726",
    email: "JuanMays@armyspy.com",
    address: "3755 Harron Drive Salisbury, MD 21875",
    rating: "4.0",
    walletBalance: "$5632",
    joiningDate: "06 Oct, 2019",
  },
  {
    id: "3",
    customerid: "#NZ1560",
    username: "Scott Henry",
    phone: "704-629-9535",
    email: "ScottHenry@jourrapide.com",
    address: "3632 Snyder Avenue Bessemer City, NC 2801",
    rating: "4.4",
    walletBalance: "$7523",
    joiningDate: "06 Oct, 2019",
  },
  {
    id: "4",
    customerid: "#NZ1559",
    username: "Cody Menendez",
    phone: "701-832-5838",
    email: "CodyMenendez@armyspy.com",
    address: "4401 Findley Avenue Minot, ND 58701",
    rating: "4.1",
    walletBalance: "$6,25",
    joiningDate: "05 Oct, 2019",
  },
  {
    id: "5",
    customerid: "#NZ1558",
    username: "Jason Merino",
    phone: "706-219-4095",
    email: "JasonMerino@dayrep.com",
    address: "3159 Holly Street Cleveland, GA 30528",
    rating: "3.8",
    walletBalance: "$4523",
    joiningDate: "04 Oct, 2019",
  },
  {
    id: "6",
    customerid: "#NZ1557",
    username: "Kyle Aquino",
    phone: "415-232-5443",
    email: "KyleAquino@teleworm.us",
    address: "4861 Delaware Avenue San Francisco, CA 94143",
    rating: "4.0",
    walletBalance: "$5412",
    joiningDate: "03 Oct, 2019",
  },
  {
    id: "7",
    customerid: "#NZ1556",
    username: "David Gaul",
    phone: "314-483-4679",
    email: "DavidGaul@teleworm.us",
    address: "1207 Cottrill Lane Stlouis, MO 63101",
    rating: "4.2",
    walletBalance: "$6180",
    joiningDate: "02 Oct, 2019",
  },
  {
    id: "8",
    customerid: "#NZ1555",
    username: "John McCray",
    phone: "253-661-7551",
    email: "JohnMcCray@armyspy.com",
    address: "3309 Horizon Circle Tacoma, WA 98423",
    rating: "4.1",
    walletBalance: "$52870",
    joiningDate: "02 Oct, 2019",
  },
]

const shops = [
  {
    id: 1,
    name: "Wiara Tecnologia",
    author: 'Marechal do Reino (MR)',
    product: 24,
    balance: "72"
  },
  {
    id: 2,
   
    author: "General do Reino (GR)",
    name: "Tiago Rocha",
    product: 1,
    balance: "12",
    profileLink: "#",
  },
  {
    id: 3,
   
    author: "General do Reino (GR)",
    name: "Nezio Monteiro",
    product: 1,
    balance: "14",
    profileLink: "#",
  },
  {
    id: 4,
   
    author: "General do Reino (GR)",
    name: "Marcos Paulo",
    product: 1,
    balance: "3",
    profileLink: "#",
  },
  {
    id: 5,
   
    author: "General do Reino (GR)",
    name: "Marcos Fergutz",
    product: 82,
    balance: "9",
    profileLink: "#",
  },
  {
    id: 6,
   
    author: "General do Reino (GR)",
    name: "Marcos Vilela",
    product: 71,
    balance: "5",
    profileLink: "#",
  },
  {
    id: 7,
   
    author: "General do Reino (GR)",
    name: "Pedro Vasco",
    product: 66,
    balance: "6",
    profileLink: "#",
  },
  {
    id: 8,
   
    author: "General do Reino (GR)",
    name: "Anderson",
    product: 58,
    balance: "7",
    profileLink: "#",
  }
]

export {
  productsData,
  recentProducts,
  comments,
  discountData,
  orders,
  shops,
  customerData,
  cartData,
}
