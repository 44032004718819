import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import RedefinirSenha from "../pages/Authentication/RedefinirSenha";
import EsqueciMinhaSenha from "../pages/Authentication/EsqueciMinhaSenha";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import Dashboard from "../pages/Dashboard/index";
import Noticias from "../pages/Noticias";

const authProtectedRoutes = [

	{ path: "/dashboard", component: Dashboard },
	{ path: "/noticias", component: Noticias },
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/esqueci-minha-senha", component: EsqueciMinhaSenha },
	{ path: "/redefinir-senha", component: RedefinirSenha },
	{ path: "/lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes }; 